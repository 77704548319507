@media (max-width: 992px) {
  .btn-mobil {
    display: block;
  }

  .header .menu {
    display: none;
  }

  .home-splash__title {
    font-size: 3.4rem;
  }

  .home-splash__text {
    font-size: 1.2rem;
  }

  .home-splash__content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .home-splash__text {
    width: 100%;
    max-width: 100%;
  }

  .title {
    font-size: 1.8rem;
  }

  .home-services__left {
    width: 100%;
  }

  .home-services__right {
    width: 100%;
    top: auto;
    position: relative;
    margin-bottom: 30px;
  }

  .home-services__list {
    flex-direction: column-reverse;
  }

  .home-references .info-box-wrapper, .service-detail-page {
    margin-top: 40px;

    .row {
      flex-direction: column-reverse;
    }
  }

  .iletisim-btn {
    font-size: 1.6rem;
  }

  .number-item {
    margin-bottom: 50px;
  }

  .number-item__big {
    font-size: 3rem;
    width: 220px;
    height: 220px;
  }

  .number-item__text {
    font-size: 1.2rem;
  }

  .cta-box {
    padding: 50px 15px;
  }

  .mb150 {
    margin-bottom: 50px;
  }

  .mt150 {
    margin-top: 50px;
  }

  .reference-menu {
    top: auto;
    width: 100%;
    margin-bottom: 30px;
    z-index: 12;

    &__list {
      display: flex;
      justify-content: flex-start;
      gap: 12px;
      flex-wrap: wrap;
    }

    &__item {
      margin: 0;
    }

    &__link {
      gap: 6px;
      font-size: 0.8rem;

      .bi {
        width: 12px;
        height: 12px;
      }
    }
  }

  .reference-page-list {
    width: 100%;
    position: relative;
    z-index: 4;

    .reference-slider__img img {
      box-shadow: none;
    }
  }

  .reference-page-list .reference-slider__item {
    position: relative;
    z-index: 7;
    margin-bottom: 50px;
  }

  .reference-slider__item--default .reference-slider__tags a {
    font-size: 0.7rem;
  }
}
