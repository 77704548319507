.btn {
  display: inline-block;
  padding: 0.375rem 1.75rem 0.42rem;
  border: 1px solid transparent;
  border-radius: 32px;
  background-color: transparent;
  color: #212529;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 1.5;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  &--big {
    padding: 0.675rem 2rem 0.72rem;
    font-size: 1.2rem;
  }

  &--bigger {
    padding: 0.875rem 2rem 0.92rem;
    font-size: 1.3rem;
  }

  &--wide {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
}

.btn-primary {
  border-color: $primary;
  background-color: $primary;
  color: #fff;

  &:hover {
    border-color: $hover_primary;
    background-color: $hover_primary;
    color: #fff;
  }
}

.btn-outline-primary{
  border-color: $primary;
  color: $primary;
  background: none;

  &:hover{
    background: $primary;
    color: #fff;
  }
}

.btn-outline-light {
  color: $text;
  border-color: #c6c6c6;
}
