@media (max-width:1200px) {
  .home-splash__title{
    font-size: 4rem;
  }

  .reference-slider .swiper-slide-active{
    transform: scale(1.2);
  }

  .custom-arrow{
    width: 48px;
  }

  .iletisim-btn{
    font-size: 2rem;
  }
}
