$text: #4f4f4f;
$primary: #256FFE;
$secondary: #E24E51;
$hover_primary: #1F4CA3;
$hover_secondary: #913441;
$line: #eee;
$black: rgba(0, 0, 0, 0.1);
$black-2: rgba(0, 0, 0, 0.2);
$black-3: rgba(0, 0, 0, 0.3);
$black-4: rgba(0, 0, 0, 0.4);
$white: rgba(255, 255, 255, 0.1);
$white-2: rgba(255, 255, 255, 0.2);
$white-3: rgba(255, 255, 255, 0.3);
$white-4: rgba(255, 255, 255, 0.4);
$white-8: rgba(255, 255, 255, 0.8);

.color--primary {
  color: $primary;
}

.color--secondary {
  color: $secondary;
}

.color--text {
  color: $text;
}

.bg--primary {
  background: $primary;
}

.bg--secondary {
  background: $secondary;
}
