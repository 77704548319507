@media (max-width: 768px) {
  .home-splash__title {
    font-size: 3rem;
  }

  #splash-text {
    display: block;
    height: 58px;
  }

  .title {
    font-size: 1.6rem;
  }

  .reference-slider .swiper-slide-active {
    transform: scale(1);
  }

  .reference-slider .swiper-slide {
    transform: scale(1);
  }

  .reference-slider__info .btn {
    display: none;
  }

  .reference-slider-wrapper {
    padding: 0 15px;
  }

  .home-services {
    padding-bottom: 30px;
  }

  .iletisim-btn {
    font-size: 1.5rem;
  }

  .number-item__big {
    font-size: 2rem;
    width: 140px;
    height: 140px;
  }

  .number-item__big svg {
    width: 36px;
    height: 36px;
  }

  .custom-arrow{
    width: 36px;
  }
}
