@media (max-width:1400px) {
  .home-services__left {
    width: 300px;
  }

  .home-services__right {
    width: calc(100% - 330px);
  }

  .service-box__text {
    font-size: 0.9rem;
  }

  .reference-slider__content {
    font-size: 1rem;
  }

  .reference-slider__title {
    font-size: 1rem;
  }

  .reference-slider__link{
    font-size: .8rem;
  }

  .reference-slider-wrapper{
    padding-left: 30px;
    padding-right: 30px;
  }
}
