@import "reset", "colors", "mixin", "global", "hamburgers", "bootstrap", "swiper", "typography", "buttons";

#__bs_notify__ {
  display: none !important;
}

.mobil-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  display: none;
  width: 100%;
  height: 100%;
  background: $black;

  &.active {
    display: block;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.mobil {
  display: none;

  &.active {
    display: block;
  }
}

#splash-text {
  color: $primary;
}

.header {
  @extend .efekt;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  padding: 15px;
  width: 100%;

  &--fixed {
    background: $black-2;
    backdrop-filter:blur(12px);
  }

  .container {
    @extend .flex;
  }
}

.menu {
  @extend .flex;
  gap: 50px;

  &__list {
    @extend .flex;
    gap: 35px;
  }

  &__item {}

  &__link {
    @extend .efekt;
    color: #fff;
    font-weight: 500;
    font-size: 1rem;

    &.active,
    &:hover {
      color: $primary;
    }
  }
}

.home-splash {
  position: relative;
  overflow: hidden;

  & > div {
    position: relative;
    z-index: 4;
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100vh;
    text-align: center;
  }

  &__title {
    display: block;
    margin-bottom: 30px;
    color: #fff;
    font-size: 5rem;
  }

  &__text {
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    width: 600px;
    text-align: center;
    font-weight: 300;
    font-size: 1.5rem;
  }

  &__discover {
    @extend .efekt;
    position: absolute;
    right: 0;
    bottom: 30px;
    left: 0;
    margin: 0 auto;
    letter-spacing: 3px;

    span {
      display: block;
      margin-bottom: 4px;
    }

    .bi {
      animation: topDown 1.4s infinite;
    }

    &:hover {
      color: $primary;
    }
  }

  .bg-radial {
    &:first-of-type {
      top: -500px;
      right: -500px;
    }

    &:last-of-type {
      bottom: -500px;
      left: -500px;
    }
  }
}
@keyframes topDown {
  0% {
    top: 0;
  }

  50% {
    top: 10px;
  }

  100% {
    top: 0;
  }
}

.bg-radial {
  position: absolute;
  z-index: 0;
  width: 1200px;
  height: 1200px;
  background: radial-gradient(circle, rgba(40,23,69,1) 0%, rgba(40,23,69,0) 57%, rgba(40,23,69,0) 100%, rgba(255,255,255,0) 003462%);
}

.title-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  width: 660px;
  text-align: center;

  .title {
    font-weight: 300;
  }

  &--left {
    margin-left: 0;
    width: 760px;
    text-align: left;
  }
}

.section {
  position: relative;
  overflow-x: hidden;
  padding: 100px 0;
}

.ref-container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 100%;
  width: 1100px;
}

.ref-item {
  img {
    width: 100%;
  }
}

.home-services {
  position: relative;
  padding: 100px 0;
  background-color: #120c23;

  &__list {
    position: relative;
    z-index: 4;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 100px;
  }

  &__left {
    width: 400px;
  }

  &__right {
    position: sticky;
    top: 150px;
    width: calc(100% - 560px);

    img {
      @extend .bxs;
      width: 100%;
      border-radius: 16px;
    }
  }

  .bg-radial {
    right: 0;
    bottom: -600px;
    left: 0;
    margin-right: auto;
    margin-left: auto;
  }
}

.service-box {
  margin-bottom: 100px;

  &__no {
    display: block;
    margin-bottom: 6px;
    color: $hover_secondary;
    font-weight: 300;
    font-size: 3rem;
  }

  &__title {
    margin-bottom: 30px;
    font-size: 1.5rem;
  }

  &__divider {
    display: block;
    margin: 30px 0;
    width: 20%;
    height: 1px;
    background: $hover_secondary;
  }

  &__text {
    font-size: 1.1rem;
    opacity: 0.8;
  }

  &__list {}

  &__item {
    margin-bottom: 15px;
  }

  &__link {
    @extend .efekt;
    display: inline-block;

    &:hover {
      color: $primary;
    }
  }
}

.home-references {
  position: relative;
  z-index: 14;
  overflow: hidden;
  background: #0f0715;

  .bg-radial {
    &:last-of-type {
      right: -500px;
      bottom: -500px;
    }
  }

  .info-box-wrapper {
    margin-top: 150px;
  }
}

.reference-slider-wrapper {
  position: relative;
  padding-right: 120px;
  padding-left: 120px;
}

.custom-arrow {
  @extend .dikeyAbsolute;
  @extend .efekt;
  z-index: 44;
  display: inline-block;

  &--prev {
    left: 0;
  }

  &--next {
    right: 0;
  }

  &:hover {
    opacity: 0.6;
  }
}

.reference-slider {
  .swiper-slide {
    @extend .efekt;
    padding: 10% 0;
    opacity: 0.6;
    filter: saturate(0%);
    transform: scale(.7);
    user-select: none;
  }

  .swiper-slide-active {
    z-index: 14;
    opacity: 1;
    filter:none;
    transform: scale(1.4);
  }

  &__item {
    &--default {
      .reference-slider__title {
        font-size: 1.2rem;
      }

      .reference-slider__info .btn {
        font-size: 1rem;
      }

      .reference-slider__tags a {
        font-size: 0.9rem;
      }
    }
  }

  &__img {
    img {
      width: 100%;
      border-radius: 16px;
      box-shadow: 0 0 250px 160px #0F0715;
    }
  }

  &__info {
    @extend .flex;
    position: relative;
    z-index: 20;
    margin-bottom: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $hover_secondary;

    .btn {
      display: inline-flex;
      align-items: center;
      padding: 6px 14px;
      font-weight: 400;
      font-size: 0.6rem;
      gap: 6px;

      .bi {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__content {
    @extend .flex;
    gap: 12px;
  }

  &__title {}

  &__divider {
    font-size: 0.9rem;
    opacity: 0.6;
  }

  &__link {
    font-weight: 300;
    font-size: 0.9rem;
    opacity: 0.6;
  }

  &__tags {
    @extend .flex;
    justify-content: flex-start;
    gap: 12px;

    a {
      @extend .efekt;
      display: inline-block;
      padding: 6px 12px;
      border: 1px solid $white-4;
      border-radius: 6px;
      background: rgba(#ffffff, 0);
      color: #fff;
      font-size: 0.6rem;

      &:hover {
        background: $white-4;
      }
    }
  }
}

.text {
  opacity: 0.6;

  p {
    display: block;
    margin-bottom: 20px;
  }
}

.info-box {
  &__content {}

  &__img {
    padding: 0 50px;
  }
}

.footer {
  &__ust {
    padding: 60px 0 30px;
    background: #08061c;

    strong {
      display: block;
      margin-bottom: 20px;
      color: #fff;
      font-weight: 600;
    }
  }

  &__alt {
    padding: 15px 0;
    background: #060414;
    color: rgba(#FFF,.8);
  }
}

.footer-menu {
  &__link {
    @include efekt(color);
    display: inline-block;
    margin-bottom: 15px;
    color: #fff;
    text-decoration: none;
    font-weight: 400;

    &:Hover {
      color: $secondary;
      text-decoration: underline;
    }
  }
}

.footer-iletisim {}

.footer-yazi {
  display: block;
  color: #BDCEEE;
  font-weight: 400;
}

.footer-link {
  @include efekt(color);
  color: #BDCEEE;
  text-decoration: none;
  font-weight: 400;

  &:Hover {
    color: $secondary;
    text-decoration: underline;
  }
}

.iletisim-btn {
  @include efekt(color);
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  font-size: 3rem;
  line-height: 1.3;

  svg {
    @include efekt(left);
    position: relative;
    left: 0;

    path,
    rect {
      @include efekt(stroke);
    }
  }

  &:Hover {
    color: $secondary;

    svg {
      left: 50px;

      path,
      rect {
        stroke: $secondary;
      }
    }
  }
}

.footer-sosyal {
  &__link {
    @include efekt(color);
    display: inline-block;
    margin-right: 15px;
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;

    .bi {
      width: 22px;
      height: 22px;
    }

    &:hover {
      color: $secondary;
    }
  }
}

.content-entry {
  position: relative;
  overflow: hidden;
  padding-top: 250px;
  padding-bottom: 100px;

  .container{
    position: relative;
    z-index: 4;
  }

  .bg-radial {
    top: -500px;
    right: -500px;

    &:last-of-type {
      top: auto;
      right: 0;
      bottom: -500px;
      left: 0;
      margin: 0 auto;
    }
  }
}

.number-item {
  position: relative;
  text-align: center;

  &__big {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    width: 260px;
    height: 260px;
    border: 1px solid $white;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(40,23,69,1) 0%, rgba(40,23,69,0) 60%, rgba(40,23,69,0) 100%, rgba(255,255,255,0) 003462%);
    font-weight: bold;
    font-size: 4rem;

    svg {
      width: 64px;
      height: 64px;
    }
  }

  &__text {
    display: block;
    color: #fff;
    font-size: 1.4rem;
  }
}

.cta-box {
  position: relative;
  padding: 80px 50px;
  height: 100%;
  border: 1px solid $white;
  border-radius: 16px;
  background: linear-gradient(56deg, rgba(40,23,69,1) 0%, rgba(40,23,69,0) 56%, rgba(40,23,69,0) 100%, rgba(255,255,255,0) 003462%);

  .flex {
    gap: 16px;
  }
}

.cta-box-img {
  border-radius: 16px;
}

.service-detail-page {
  .bg-radial {
    &:nth-of-type(2) {
      top: 30%;
      left: -500px;
    }
  }
}

.reference-wrapper {
  position: relative;
  z-index: 4;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.reference-page-list {
  width: calc(100% - 400px);

  .reference-slider__item {
    margin-bottom: 100px;
  }
}

.z-pos {
  position: relative;
  z-index: 20;
}

.reference-menu {
  position: relative;
  position: sticky;
  top: 120px;
  z-index: 4;
  padding: 15px;
  width: 360px;
  border: 1px solid $white;
  border-radius: 16px;
  background: linear-gradient(56deg, rgba(40,23,69,1) 0%, rgba(40,23,69,0) 56%, rgba(40,23,69,0) 100%, rgba(255,255,255,0) 003462%);

  &__list {}

  &__item {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    @extend .efekt;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
    border: 1px solid $white;
    border-radius: 14px;
    font-weight: 500;
    gap: 12px;

    &.active,
    &:hover {
      background: $primary;
    }
  }
}

.form-control {
  height: 52px;
  border: 1px solid $white-2;
  border-radius: 12px;
  background: transparent;
  color: #fff;
  transition: all 0.3s !important;

  &:Hover,
  &:focus {
    border-color: $white-4;
    background-color: $white;
    color: #fff;
  }
}

.container.section{
  padding-left: 15px;
  padding-right: 15px;
}

.home-services .bg-radial{
  max-width: 100%;
}

.contact-form{
  margin-top: 50px;
}
@import "style1600", "style1400", "style1200", "mobil-nav", "style992", "style768", "style576";
